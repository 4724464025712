import * as React from 'react'

const BasicInfo = ({ mdx }) => {
  return (
    <div className='flex flex-col my-3 py-3 border-y text-sm'>
      <img className="my-3" src={mdx.frontmatter.cover_image}></img>
      <p className='pr-5'>🌟：{mdx.frontmatter.star}</p>
      <p className='pr-5'>平台：{mdx.frontmatter.platforms}</p>
      <p>類型：
        {
        mdx.frontmatter.tags.map(tag => (
          <span key={tag} className='outline outline-1 rounded px-1 mx-1'>{tag}</span>
        ))
        }
    </p>
    </div>
  )
}

export default BasicInfo
