import * as React from 'react'
import Layout from '../../components/layout'
import { graphql } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import BasicInfo from '../../components/game_guide/basic_info'

const GameGuide = ({ data }) => {
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      {
      <article key={data.mdx.id}>
        <h1>{data.mdx.frontmatter.title}</h1>
        <h2 className='text-theme-secondary-color'>{data.mdx.frontmatter.sub_title}</h2>
        <p className="text-sm">{data.mdx.frontmatter.date}</p>
        <BasicInfo mdx={data.mdx}></BasicInfo>
        <MDXRenderer>
          {data.mdx.body}
        </MDXRenderer>
        <div className='mt-10 overflow-hidden'>
          <div className="mt-5 md:block hidden" dangerouslySetInnerHTML={{ __html:data.mdx.frontmatter.buy_link }}></div>
          <div className="mt-5 md:hidden block" dangerouslySetInnerHTML={{ __html:data.mdx.frontmatter.buy_link_mobile }}></div>
        </div>
      </article>
      }
    </Layout>
    )
}

export const query = graphql`
query game_review($id: String) {
  mdx(id: {eq: $id}) {
    frontmatter {
        title
        star
        cover_image
        tags
        platforms
        sub_title
        buy_link
        buy_link_mobile
        date(formatString: "MMMM DD YYYY")
    }
    body
  }
}
`

export default GameGuide
